import { template as template_c62f066417904f23a9c8d3f8e3a5293a } from "@ember/template-compiler";
const WelcomeHeader = template_c62f066417904f23a9c8d3f8e3a5293a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
