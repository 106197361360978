import { template as template_8963837a51be48188a0dedbfb097f6a2 } from "@ember/template-compiler";
const SidebarSectionMessage = template_8963837a51be48188a0dedbfb097f6a2(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
